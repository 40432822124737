import React from 'react';
import Row from 'react-bootstrap/Row';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {travelPhotos} from "./Photos"
import NewGallery from './components/NewGallery'

const Travel = () => {  
    return (  
      <div className="container gallery-container">
        <Row>
          <NewGallery photos={travelPhotos}></NewGallery>
        </Row>
      </div>    
    );
}
export default Travel;