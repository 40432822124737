import travel_img from './img/travel.json'
import portrait_img from './img/portrait.json'


//const images_list = importAll(require.context('../public/images/travel/', false, /\.(png|jpe?g|svg)$/));
const travelPhotos = travel_img.map(x => ({src: x.thumb, landscape: x.landscape, thumb: x.src, 
                                width: x.width, height: x.height}))
const portraitPhotos = portrait_img.map(x => ({src: x.thumb, landscape: x.landscape, thumb: x.src, width: x.width, height: x.height}))


export {travelPhotos, portraitPhotos};