import React, { useState, useCallback } from "react";
import Gallery from "react-photo-gallery";
import 'bootstrap/dist/css/bootstrap.min.css';
import Image from 'react-bootstrap/Image';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';


  

const NewGallery = (props) => {
    const [currentImage, setCurrentImage] = useState(0);
    const [modalShow, setModalShow] = React.useState(false);  

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index);
        setModalShow(true);
      }, []);

    function Modalcenter(props) {
        return (
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <Image src={props.url} fluid/>
                </Modal.Body>
            </Modal>
        );
    }

    return (
      <div>
        <Gallery photos={props.photos} onClick={openLightbox} />
        {modalShow ? (
        <Modalcenter
            url = {props.photos[currentImage].thumb}
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
        ) : null }
      </div>
    );
  }


export default NewGallery