import React from 'react';
import Row from 'react-bootstrap/Row';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {portraitPhotos} from "./Photos"
import NewGallery from './components/NewGallery'

const Portrait = () => {  
  return (  
    <div className="container gallery-container">
      <Row>
        <NewGallery photos={portraitPhotos}></NewGallery>
      </Row>
    </div>    
  );
}
export default Portrait;