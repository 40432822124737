import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Nav from 'react-bootstrap/Nav';
import Portrait from "./Portrait";
import Travel from "./Travel";
import About from "./About";
import { Switch, Route, Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { Container } from 'react-bootstrap';
import { NavLink } from 'react-bootstrap';
import { BrowserRouter as Router } from 'react-router-dom';

const App = () => {  
    document.title = 'WusColorspace'
    return (
      <>
      <div className="container">
          <Container className="st-title">
              <h1>WUSCOLORSPACE</h1>
          </Container>
          <Nav className="justify-content-center" as="ul">
            <Nav.Item as="li">
              <Nav.Link href="/"> Home </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li">
              <NavLink href="/portrait"> Portrait </NavLink>
            </Nav.Item>
            <Nav.Item as="li">
              <NavLink href="/travel"> Travel </NavLink>
            </Nav.Item>
            <Nav.Item className="fab fa-instagram" as="li">
              <Nav.Link href="https://www.instagram.com/wus.colorspace/"> <FontAwesomeIcon icon={faInstagram}/> 
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="fa fa-email" as="li">
              <Nav.Link href="mailto:wuscolorspace@gmail.com"> <FontAwesomeIcon icon={faEnvelope}/> 
              </Nav.Link>
            </Nav.Item>
          </Nav>
      </div>
      <div>
        <Router>
          <Switch>
            <Route key={1} exact path='/' component={Portrait} />
            <Route key={2} path='/portrait' component={Portrait} />
            <Route key={3} path='/travel' component={Travel} />
            <Route key={5} render={function () {
              return <p>Not found</p>
            }} />
            </Switch>
        </Router>
      </div>

        <Container className="st-footer">
          <footer>
            © WusColorspace 2020. All Right Reserved. Copyright notice.
          </footer>
        </Container>
      </>      
    );
}
export default App;
